import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import NProgress from 'nprogress';

import { history } from '../..';
import { IModule, ITraining, IUser } from '../models';
import { SignUp, Signin, IUserChange } from '../models/forms';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config: AxiosRequestConfig<any>) => {
  const token = localStorage.getItem('jwt');

  const headers = {
    'Content-Type': 'application/json',
  };

  config.headers = headers;

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.request.use(
  (config) => {
    NProgress.start();
    return config;
  },
  (error) => {
    throw new Error(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      localStorage.removeItem('jwt');
      if (history.location.pathname !== '/auth') {
        history.push('/auth');
      }
    }

    NProgress.done();
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  download: <T>(url: string) => axios.get<T>(url).then(responseBody),
};

const Auth = {
  signIn: (signIn: Signin) =>
    requests.post<{ status: number; token: string }>('/auth/signin', signIn),
};

const Modules = {
  getList: () => requests.get<IModule[]>('/modules'),
  post: (module: IModule) => requests.post<IModule>('/modules', module),
  put: (module: IModule) =>
    requests.put<IModule>(`/modules/${module._id}`, module),
  delete: (id: string) => requests.del<void>(`/modules/${id}`),
  reorder: (modules: IModule[]) =>
    requests.post<IModule>('/modules/reorder', modules),
};

const Trainings = {
  getList: () => requests.get<ITraining[]>('/trainings'),
  post: (training: ITraining) =>
    requests.post<ITraining>('/trainings', training),
  put: (training: ITraining) =>
    requests.put<ITraining>(`/trainings/${training._id}`, training),
  delete: (id: string) => requests.del<void>(`/trainings/${id}`),
  reorder: (trainings: ITraining[]) =>
    requests.post<ITraining>('/trainings/reorder', trainings),
};

const Users = {
  getList: () => requests.get<IUser[]>('/users'),
  post: (user: SignUp) => requests.post<IUser>('/users', user),
  put: (id: string, user: IUserChange) =>
    requests.put<IUser>(`/users/${id}`, user),
  delete: (id: string) => requests.del<void>(`/users/${id}`),
};

const agent = {
  Auth,
  Modules,
  Trainings,
  Users,
};

export default agent;
