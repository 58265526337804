import React from 'react';
import VrIcon from './VrIcon';

interface Props {
  className: string;
  iconColor: string;
  pingColor: string;
  size: string;
}

const PingVrIcons: React.FC<Props> = ({
  className,
  iconColor,
  pingColor,
  size,
}) => {
  return (
    <span className={className}>
      <VrIcon
        className={`relative animate-ping opacity-40 ${pingColor} ${size}`}
      />
      <VrIcon className={`absolute rounded-full ${iconColor} ${size}`} />
    </span>
  );
};

export default PingVrIcons;
