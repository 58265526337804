import { QueryClient } from 'react-query';
import { toast } from 'react-toastify';
import request from 'axios';

function queryErrorHandler(error: unknown): void {
  // error is type unknown because in js, anything can be an error (e.g. throw(5))
  console.log(error);
  console.log(request.isAxiosError(error));
  const data = request.isAxiosError(error) ? error.response?.data : null;
  const title = request.isAxiosError(error)
    ? data
      ? data.message
      : 'error connecting to server'
    : 'Unknow error';

  // prevent duplicate toasts
  toast.error(title);
}

// to satisfy typescript until this file has uncommented contents
export function generateQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        onError: queryErrorHandler,
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // default cacheTime is 5 minutes; doesn't make sense for staleTime to exceed cacheTime
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
      mutations: {
        onError: queryErrorHandler,
      },
    },
  });
}

export const queryClient = generateQueryClient();
