import { useEffect, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { saveAs } from 'file-saver';

import { queryKeys } from '../react-query/constants';
import useShowMessage from './useShowMessage';
import silentAgent from '../helpers/silentAgent';
import { useSearchParams } from 'react-router-dom';

const useReports = () => {
  const { successMessage } = useShowMessage();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const [page, setPage] = useState(
    isNaN(
      Number.parseInt(
        !searchParams.get('page') ? 'a' : searchParams.get('page')!
      )
    )
      ? '1'
      : searchParams.get('page')
  );

  useEffect(() => {
    if (pageParam !== page) {
      setPage(pageParam);
    }
  }, [pageParam, page]);

  const {
    isLoading,
    data = {
      list: [],
      total: 0,
    },
  } = useQuery(
    [queryKeys.reports, page],
    () => silentAgent.Reports.getList(+page!),
    {
      refetchInterval: 5000,
      refetchOnWindowFocus: true,
      refetchOnMount: 'always',
      refetchOnReconnect: true,
    }
  );

  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    async (id: string) => {
      await silentAgent.Reports.delete(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.reports);
        successMessage('Delete report success');
      },
    }
  );

  const downloadExcel = async () => {
    const res = await silentAgent.Reports.getExcel();

    var blob = new Blob([res], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(blob, 'reports.xlsx');
  };

  const nextPage = () => {
    const next = +page! + 1;
    setPage(next.toString());
    setSearchParams({
      page: next.toString(),
    });
  };

  const previousPage = () => {
    const prev = +page! - 1;
    setPage(prev.toString());
    setSearchParams({
      page: prev.toString(),
    });
  };

  return {
    reports: data.list,
    totalCount: data.total,
    isLoading,
    deleteMutation,
    currentPage: +page!,
    nextPage,
    previousPage,
    downloadExcel,
  };
};

export default useReports;
