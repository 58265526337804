import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { history } from '../..';
import NProgress from 'nprogress';

import { IDevice, ITraining } from '../models';
import { IStartTrainingForm } from '../models/forms';
import { IReport } from '../models/report';

const silentAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const downoladAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

downoladAxios.interceptors.request.use((config: AxiosRequestConfig<any>) => {
  const token = localStorage.getItem('jwt');

  const headers = {
    'Content-Type': 'application/vnd.ms-excel',
  };

  config.responseType = 'arraybuffer';

  config.headers = headers;

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

downoladAxios.interceptors.request.use(
  (config) => {
    NProgress.start();
    return config;
  },
  (error) => {
    throw new Error(error);
  }
);

downoladAxios.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      localStorage.removeItem('jwt');
      if (history.location.pathname !== '/auth') {
        history.push('/auth');
      }
    }

    NProgress.done();
    return Promise.reject(error);
  }
);

silentAxios.interceptors.request.use((config: AxiosRequestConfig<any>) => {
  const token = localStorage.getItem('jwt');

  const headers = {
    'Content-Type': 'application/json',
  };

  config.headers = headers;

  // config.responseType = 'arraybuffer';

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

silentAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      localStorage.removeItem('jwt');
      console.log('Detect error');
      if (history.location.pathname !== '/auth') {
        console.log('Run');
        history.push('/auth');
      }
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => silentAxios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    silentAxios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) =>
    silentAxios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => silentAxios.delete<T>(url).then(responseBody),
  download: <T>(url: string) => downoladAxios.get<T>(url).then(responseBody),
};

const DevicesStatus = {
  getDevices: () => requests.get<IDevice[]>('/devices-status/devices'),
  postStartTraining: (trainingEvent: IStartTrainingForm) =>
    requests.post<ITraining>('/devices-status/start-training', trainingEvent),
  putDeviceName: (deviceId: string, name: string) =>
    requests.put<ITraining>(`/devices-status/${deviceId}`, { name }),
  delDeviceName: (deviceId: string) =>
    requests.del<ITraining>(`/devices-status/${deviceId}`),
};

const Reports = {
  getList: (page: number) =>
    requests.get<{ list: IReport[]; total: number }>(`/reports/?page=${page}`),
  delete: (id: string) => requests.del<void>(`/reports/${id}`),
  getExcel: () => requests.download<Blob>('/reports/excel'),
};

const Downloads = {
  getManual: () => requests.download<Blob>('/downloads/manual'),
};

const silentAgent = {
  DevicesStatus,
  Reports,
  Downloads,
};

export default silentAgent;
