import React from 'react';

import useModule from '../../app/hooks/useModule';
import ModulesTable from '../../app/components/modules/ModulesTable';

const ModulesPage: React.FC = () => {
  const { modules } = useModule();

  return <>{<ModulesTable modules={modules} />}</>;
};

export default ModulesPage;
