import React from 'react';

import { FieldMetaProps, useField } from 'formik';
import ValidationError from './ValidationError';

interface Props {
  name: string;
  label: string;
  prefixIcon?: React.ReactNode;
  className?: string;
}

const TextInput: React.FC<Props> = ({
  name,
  label,
  prefixIcon,
  className,
}: Props) => {
  const [filed, meta] = useField(name);

  const inputClass = createInputCss(meta, prefixIcon);

  return (
    <div className={className + ' mb-4'}>
      <label
        htmlFor={name}
        className='block pl-1 text-xs font-semibold text-gray-400'
      >
        {label}
      </label>
      <div className='relative rounded-md shadow-sm'>
        {prefixIcon && (
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none primary-color'>
            {prefixIcon}
          </div>
        )}
        <input
          id={name}
          className={inputClass}
          type='text'
          placeholder={label}
          autoComplete='off'
          {...filed}
          value={filed.value}
        />
      </div>
      <ValidationError touched={meta.touched} error={meta.error} />
    </div>
  );
};

const createInputCss = (
  meta: FieldMetaProps<any>,
  prefixIcon: React.ReactNode
): string => {
  let inputClass = '';

  if (meta.touched && meta.error) {
    inputClass =
      'block w-full p-3 sm:text-sm rounded-md focus:outline-none border focus:border-[#005CB4] border-red-300';
  } else {
    inputClass =
      'block w-full p-3 sm:text-sm rounded-md focus:outline-none border focus:border-[#005CB4] border-gray-300';
  }

  inputClass = prefixIcon ? inputClass.concat(' pl-10') : inputClass;

  return inputClass;
};

export default TextInput;
