import React from 'react';

interface Props {
  headTitles: string[];
  lastRight?: boolean;
}

const TableHead: React.FC<Props> = ({ headTitles, lastRight = true }) => {
  return (
    <thead className='bg-gray-50'>
      <tr>
        {headTitles.map((e, index) => (
          <th
            key={index}
            scope='col'
            className={
              index === 0
                ? 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                : index === headTitles.length - 1 && lastRight
                ? 'px-3 py-3.5 text-right text-sm font-semibold text-gray-900'
                : 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
            }
          >
            {e}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
