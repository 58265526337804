import { FieldMetaProps } from 'formik';
import { StylesConfig, Theme, ThemeConfig } from 'react-select';

export const selectTheme: ThemeConfig = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#005CB4',
      primary: '#005CB4',
    },
    borderRadius: 1,
  } as Theme;
};

export function getSelectedStyles(meta: FieldMetaProps<any>): StylesConfig {
  return {
    menu: (provided: any, state: any) => ({
      ...provided,
      overflow: 'visible',
    }),
    container: (provide: any) => ({
      ...provide,
      borderRadius: '0.375rem',
      borderColor: meta.touched && meta.error ? '#fca5a5' : '#d1d5db',
      borderStyle: 'solid',
    }),
    control: (provide: any) => ({
      ...provide,
      padding: '0.36rem',
      borderRadius: '0.375rem',
      border: '2px',
      boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    }),
  };
}
