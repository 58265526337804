import { FormikErrors } from 'formik';
import isEmpty from 'validator/lib/isEmpty';

export interface IModule {
  _id?: string;
  order: number;
  name: string;
  description: string;
}

export function createModuleForm(module?: IModule | null): Partial<IModule> {
  return {
    name: module ? module.name : '',
    description: module ? module.description : '',
    order: 0,
  };
}

export function validateModule(
  values: Partial<IModule>
): FormikErrors<Partial<IModule>> {
  const errors: FormikErrors<Partial<IModule>> = {};

  if (isEmpty(values.name!)) errors.name = 'required';
  if (isEmpty(values.description!)) errors.description = 'required';

  return errors;
}
