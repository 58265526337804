import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IStartTrainingForm } from '../models/forms';
import useShowMessage from './useShowMessage';
import silentAgent from '../helpers/silentAgent';
import { queryKeys } from '../react-query/constants';
import usePropterUtils from './usePropterUtils';

const useDevicesStatus = () => {
  const { successMessage, errorMessage } = useShowMessage();
  const { isActiveDevice } = usePropterUtils();

  const { isLoading, data = [] } = useQuery(
    queryKeys.deviceStatus,
    silentAgent.DevicesStatus.getDevices,
    {
      refetchInterval: 5000,
    }
  );

  const activeDevice = [...data.filter((e) => isActiveDevice(e))];

  const inactiveDevice = [...data?.filter((e) => !isActiveDevice(e))];

  const queryClient = useQueryClient();

  const startTraining = async (newTraining: IStartTrainingForm) => {
    try {
      await silentAgent.DevicesStatus.postStartTraining(newTraining);
      queryClient.invalidateQueries(queryKeys.deviceStatus);
      successMessage('Training start success');
    } catch (error) {
      errorMessage('Training start error');
    }
  };

  const renameDevice = async (deviceId: string, name: string) => {
    try {
      await silentAgent.DevicesStatus.putDeviceName(deviceId, name);
      queryClient.invalidateQueries(queryKeys.deviceStatus);
      successMessage('Device rename success');
    } catch (error) {
      errorMessage('Device rename error');
    }
  };

  const deleteMutation = useMutation(
    (id: string) => silentAgent.DevicesStatus.delDeviceName(id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries();
        successMessage('Delete device success');
      },
    }
  );

  return {
    activeDevice,
    inactiveDevice,
    isLoading,
    startTraining,
    renameDevice,
    deleteMutation,
  };
};

export default useDevicesStatus;
