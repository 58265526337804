import React, { useEffect, useState } from 'react';

import { ClockIcon } from '@heroicons/react/outline';

interface Props {
  text: string;
  isLoading: boolean;
  disabled?: boolean;
  widthFull?: boolean;
}

const SubmitButton: React.FC<Props> = ({
  text,
  disabled,
  isLoading = false,
  widthFull = true,
}: Props) => {
  const [loading, setLoading] = useState(isLoading);

  let fullWidth = widthFull ? ' w-full' : '';

  useEffect(() => {
    isLoading && setLoading(true);
    const timeout = setTimeout(() => {
      !isLoading && setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <button
      type='submit'
      disabled={disabled}
      className={`flex items-center justify-center mt-2 disabled:bg-blue-200 disabled:text-white font-semibold leading-none text-white py-4 px-10 bg-primary-color rounded hover:bg-primary-color focus:ring-2 focus:ring-offset-2 focus:bg-primary-color focus:outline-none ${fullWidth}`}
    >
      {loading && (
        <>
          {text}
          <ClockIcon className='animate-spin h-4 w-4 ml-2 text-white' />
        </>
      )}
      {!loading && <>{text}</>}
    </button>
  );
};

export default SubmitButton;
