import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  AcademicCapIcon,
  ChartBarIcon,
  ClipboardIcon,
  DotsVerticalIcon,
  HomeIcon,
  MenuAlt2Icon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import VrIcon from '../icons/VrIcon';

const useNavigation = (role: string) => {
  let navigation = [
    { name: 'Home', href: '/', icon: HomeIcon, current: true },
    { name: 'Devices', href: '/devices', icon: VrIcon, current: true },
    {
      name: 'Reports',
      href: '/reports?page=1',
      icon: ChartBarIcon,
      current: false,
    },
    {
      name: 'Trainings',
      href: '/trainings',
      icon: AcademicCapIcon,
      current: false,
    },
    {
      name: 'Module',
      href: '/modules',
      icon: ClipboardIcon,
      current: false,
    },
    {
      name: 'Manual',
      href: '/manual',
      icon: QuestionMarkCircleIcon,
      current: false,
    },
  ];

  navigation =
    role === 'admin' || role === 'superadmin'
      ? [
          ...navigation,
          ...[
            {
              name: 'Users',
              href: '/users',
              icon: UserGroupIcon,
              current: false,
            },
          ],
        ]
      : [...navigation];

  return navigation;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface Props {
  children: React.ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);
  const { signout, getUserNameFormToken, getRoleFromToken } = useAuth();
  const navigate = useNavigate();
  const userName = getUserNameFormToken();
  const role = getRoleFromToken();
  const navigation = useNavigation(role);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='fixed inset-0 flex z-40 md:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute top-0 right-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className='flex flex-col items-center px-4 '>
                  <img
                    className='h-9 w-auto mb-2'
                    src='../assets/images/logo.png'
                    alt='Pepsico'
                  />
                  <span className='text-accent-color uppercase text-xs'>
                    Training vr portal
                  </span>
                </div>
                <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                  <nav className='px-2 space-y-1'>
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        replace={false}
                        onClick={() => {
                          setSidebarOpen(false);
                          setCurrent(item.href);
                        }}
                        className={classNames(
                          current === item.href
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className='flex-shrink-0 w-14' aria-hidden='true'>
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex-1 flex flex-col min-h-0 bg-gray-800'>
            <div className='flex items-center h-20 flex-shrink-0 px-4 bg-gray-800'>
              <div className='flex flex-col justify-center px-4'>
                <img
                  className='h-9 w-auto mb-2'
                  src='../assets/images/logo.png'
                  alt='Pepsico'
                />
                <span className='accent-color uppercase text-xs'>
                  Training vr portal
                </span>
              </div>
            </div>
            <div className='flex-1 flex flex-col overflow-y-auto'>
              <nav className='flex-1 px-2 py-4 space-y-1'>
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => setCurrent(item.href)}
                    className={classNames(
                      item.href === current
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className='md:pl-64 flex flex-col'>
          <div className='sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow'>
            <button
              type='button'
              className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
            </button>
            <div className='flex-1 px-4 flex justify-between'>
              <div className='flex-shrink-0 flex text-center items-center px-4 pt-4 font-semibold'></div>
              <div className='flex-1 flex'>
                {/* <form className='w-full flex md:ml-0' action='#' method='GET'>
                  <label htmlFor='search-field' className='sr-only'>
                    Search
                  </label>
                  <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                    <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                      <SearchIcon className='h-5 w-5' aria-hidden='true' />
                    </div>
                    <input
                      id='search-field'
                      className='block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
                      placeholder='Search'
                      type='search'
                      name='search'
                    />
                  </div>
                </form> */}
              </div>
              <div className='ml-4 flex items-center md:ml-6'>
                {/* <button
                  type='button'
                  className='bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                </button> */}

                {/* Profile dropdown */}
                <Menu as='div' className='ml-3 relative'>
                  <div>
                    <Menu.Button className='max-w-xs p-2 text-gray-500 hover:ring-2 hover:ring-red-800 bg-white flex items-center text-sm rounded-md'>
                      <span className='sr-only'>Open user menu</span>
                      <span className='accent-color'>
                        <DotsVerticalIcon
                          className='h-5 w-5'
                          aria-hidden='true'
                        />
                      </span>
                      {userName && <div className='mx-2'>{userName}</div>}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <Menu.Item
                        onClick={() => {
                          signout();
                          navigate('../auth', { replace: true });
                        }}
                      >
                        {({ active }) => (
                          <div className='flex flex-col items-center justify-center content-center'>
                            <button
                              className={classNames(
                                active ? 'bg-yellow-200' : '',
                                'flex w-full px-4 py-2 text-sm text-gray-700 font-bold'
                              )}
                            >
                              <XIcon className='w-5 h-5 mr-5 text-red-500' />
                              Sign Out
                            </button>
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className='flex-1'>{children}</main>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
