import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useUsers from '../../hooks/useUsers';
import { IUser } from '../../models';
import {
  createSignUp,
  IUserChange,
  SignUp,
  validateSignUp,
} from '../../models/forms';
import CloseButton from '../controls/CloseButton';
import EmailInput from '../controls/EmailInput';
import PasswordInput from '../controls/PasswordInput';
import RoleSelect from '../controls/RoleSelect';
import SubmitButton from '../controls/SubmitButton';
import FormRow from '../layout/FormRow';
import FormTitle from '../layout/FormTitle';

interface Props {
  user?: IUser | null;
}

const UserForm: React.FC<Props> = ({ user }: Props) => {
  const navigate = useNavigate();
  const { putMutation, postMutation } = useUsers();

  return (
    <>
      <FormTitle title='User' rightButton={<CloseButton />} />
      <Formik
        initialValues={createSignUp(user)}
        validate={(values) => validateSignUp(values, user)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          if (user && user._id) {
            const update = {
              _id: user._id,
              name: values.name,
              password: values.password,
              role: values.role,
            } as IUserChange;

            await putMutation.mutateAsync(update);
            resetForm({ values });
            navigate(-1);
          } else {
            const newUser = {
              name: values.name,
              password: values.password,
              role: values.role,
            } as SignUp;
            await postMutation.mutateAsync(newUser);
            resetForm({ values });
            navigate(-1);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form>
            <div className='max-w-xl'>
              <EmailInput
                name='name'
                label='User name'
                className='md:col-span-12'
              />
              <PasswordInput name='password' label='New Password' />
              <PasswordInput name='confirmPassword' label='Confirm Password' />
              <FormRow>
                <RoleSelect
                  name='role'
                  label='Roles'
                  className='md:col-span-12'
                />
              </FormRow>
            </div>
            <div className='pb-5'>
              <SubmitButton
                isLoading={isSubmitting}
                text='Submit'
                widthFull={false}
                disabled={isSubmitting || !dirty || !isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserForm;
