import { Formik, Form } from 'formik';
import React from 'react';
import useTrainingEvents from '../../hooks/useDevicesStatus';

import {
  createTrainingEvent,
  validateStartTrainingForm,
} from '../../models/forms';
import { IDevice } from '../../models';
import StartButton from '../controls/StartButton';
import TextInput from '../controls/TextInput';
import TrainingSelect from '../controls/TrainingSelect';
import FormRow from '../layout/FormRow';
import LanguageSelect from '../controls/LanguageSelect';

interface Props {
  device: IDevice;
}

const DeviceForm: React.FC<Props> = ({ device }) => {
  const { startTraining } = useTrainingEvents();
  return (
    <div className='mt-2'>
      <Formik
        initialValues={createTrainingEvent(device.deviceId, device.deviceName)}
        validate={(values) => validateStartTrainingForm(values)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const newTraining = {
            ...values,
            deviceId: device.deviceId,
            deviceName: device.deviceName,
          };
          await startTraining(newTraining);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form>
            <FormRow>
              <TrainingSelect
                name='training'
                label='Training'
                className='md:col-span-12'
              />
            </FormRow>
            <FormRow>
              <LanguageSelect
                name='language'
                label='Language'
                className='md:col-span-12'
              />
            </FormRow>
            <FormRow>
              <TextInput
                name='employeeId'
                label='Employee ID'
                className='md:col-span-12'
              />
            </FormRow>
            <FormRow>
              <TextInput
                name='employeeName'
                label='Employee Name'
                className='md:col-span-12'
              />
            </FormRow>
            <div className='pb-5'>
              <StartButton
                isLoading={isSubmitting}
                text='START TRAINING'
                widthFull={false}
                disabled={isSubmitting || !dirty || !isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DeviceForm;
