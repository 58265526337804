import React from 'react';
import { useSearchParams } from 'react-router-dom';

import isMongoId from 'validator/lib/isMongoId';
import LoadingSpinner from '../../app/components/layout/LoadingSpinner';
import ReportTable from '../../app/components/reports/ReportTable';
import useReports from '../../app/hooks/useReports';
import { IReport } from '../../app/models/report';

const ReportPage: React.FC = () => {
  const { isLoading } = useReports();
  const report = useReport();

  return (
    <>
      {isLoading && <LoadingSpinner />}

      {report && !isLoading && <ReportTable report={report} />}
    </>
  );
};

const useReport = (): IReport | undefined => {
  const [searchParams] = useSearchParams();
  const report = searchParams.get('report');

  const { reports, isLoading } = useReports();

  if (isMongoId(report ? report : '') && !isLoading) {
    return reports ? reports.find((e) => e._id === report) : undefined;
  } else {
    return undefined;
  }
};

export default ReportPage;
