import React from 'react';

import { IReport } from '../../models/report';
import CloseButton from '../controls/CloseButton';
import TableContainer from '../layout/TableContainer';
import TableHead from '../layout/TableHead';

interface Props {
  report: IReport;
}

const ReportTable: React.FC<Props> = ({ report }) => {
  return (
    <div className='px-4 sm:px-6 lg:px-8 mt-6'>
      <TableHeader
        title={`Report for training ${report.training.name}`}
        subtitle={`Employee ID ${report.employeeId} `}
      />
      <TableContainer>
        <TableHead
          lastRight={false}
          headTitles={['Scene name', 'Quiz result', 'Time Elapsed', 'Status']}
        />

        <tbody className='divide-y divide-gray-200 bg-white'>
          {report.progress.map((progres, reportIdx) => (
            <tr key={progres._id}>
              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                {progres.sceneName}
              </td>
              <td className='px-3 py-4 text-sm text-gray-500'>
                {progres.quizResult}
              </td>
              <td className='px-3 py-4 text-sm text-gray-500'>
                {progres.timeElapsed}
              </td>
              <td className='px-3 py-4 text-sm text-gray-500'>
                {progres.status}
              </td>
            </tr>
          ))}
        </tbody>
      </TableContainer>
    </div>
  );
};

interface TableProps {
  title: string;
  subtitle?: string;
}

const TableHeader: React.FC<TableProps> = ({ title, subtitle }) => {
  return (
    <div className='sm:flex sm:items-center border-[#C9002B] border-b-2 pb-1'>
      <div className='sm:flex-auto'>
        <h1 className='text-xl font-semibold text-gray-900'>{title}</h1>
        <p className='mt-2 text-sm text-gray-700'>{subtitle}</p>
      </div>

      <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
        <CloseButton />
      </div>
    </div>
  );
};

export default ReportTable;
