import React from 'react';
import PingVrIcons from '../icons/PingVrIcons';

const DevicesEmptyState: React.FC = () => {
  return (
    <div className='text-center bg-yellow-50 border-l-4 border-yellow-400 p-6 rounded-xl'>
      <PingVrIcons
        className='flex justify-center ml-auto mr-auto'
        iconColor='text-yellow-500'
        pingColor='text-yellow-400'
        size='w-10 h-10'
      />
      <h2 className='mt-2 text-lg font-medium text-gray-900'>
        No active devices
      </h2>
      <p className='mt-1 text-sm text-gray-500'>
        Launch the device to enter the trening
      </p>
    </div>
  );
};

export default DevicesEmptyState;
