import { useMutation, useQuery, useQueryClient } from 'react-query';

import { IModule } from '../models/module';
import agent from '../helpers/agent';
import useShowMessage from './useShowMessage';
import { queryKeys } from '../react-query/constants';

const useModule = () => {
  const { successMessage } = useShowMessage();
  const { isLoading, data = [] } = useQuery(
    queryKeys.modules,
    agent.Modules.getList,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const queryClient = useQueryClient();

  const postMutation = useMutation(
    (newModule: IModule) => agent.Modules.post(newModule),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        successMessage('Create module success');
      },
    }
  );

  const putMutation = useMutation(
    (module: IModule) => agent.Modules.put(module),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.modules);
        queryClient.invalidateQueries(queryKeys.trainings, {
          refetchInactive: true,
        });
        successMessage('Update module success');
      },
    }
  );

  const deleteMutation = useMutation((id: string) => agent.Modules.delete(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(queryKeys.modules);
      successMessage('Delete module success');
    },
  });

  return {
    modules: data,
    postMutation,
    putMutation,
    deleteMutation,
    isLoading,
  };
};

export default useModule;
