import React, { Fragment, useRef } from 'react';

import { PlusIcon } from '@heroicons/react/outline';
import { Popover, Transition } from '@headlessui/react';
import usePropterUtils from '../../hooks/usePropterUtils';
import { IModule } from '../../models/module';

interface Props {
  modules: IModule[];
  includedModule: IModule[];
  onSelect: (module: IModule) => void;
}

const TraininAddModule: React.FC<Props> = ({
  modules,
  includedModule,
  onSelect,
}) => {
  const buttonRef: any = useRef();
  const { classNames } = usePropterUtils();

  const filteredModule = useFileterModule(modules, includedModule);

  return (
    <Popover className='relative'>
      {({ open }) => (
        <>
          <Popover.Button
            ref={buttonRef}
            className={classNames(
              open ? 'text-gray-500' : 'text-gray-900',
              'group mb-3 p-1 bg-white rounded-md inline-flex text-base font-medium hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#005CB4]'
            )}
          >
            <div className='flex flex-col justify-center items-center'>
              <span className='flex font-semibold text-xl pb-2'>
                <p>INCLUDE MODULE</p>
                <PlusIcon
                  className={
                    'text-[#005CB4] ml-2 h-6 w-6 group-hover:text-[#C9002B]'
                  }
                  aria-hidden='true'
                />
              </span>
              {/* <span className='text-sm text-gray-500'>{changeTemplate}</span> */}
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel
              onClick={() => buttonRef.current?.click()}
              className='absolute z-10 transform pl-auto pr-auto max-w-xs sm:px-0'
            >
              <div className='absolute md:max-w-2xl rounded-lg shadow-lg'>
                <div className='grid gap-5 w-screen max-w-sm md:max-w-xl bg-white px-5 py-6 sm:gap-8 sm:p-8 overflow-auto'>
                  {filteredModule.map((item) => (
                    <div
                      key={item._id}
                      onClick={() => onSelect(item)}
                      className='-m-3 p-3 border-[1px] rounded-md hover:bg-blue-300 transition ease-in-out duration-150'
                    >
                      <p className='text-base font-medium text-gray-900'>
                        {item.name}
                      </p>
                      <p className='mt-1 text-sm text-gray-500'>
                        {item.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

const useFileterModule = (modules: IModule[], includes: IModule[]) => {
  const result: IModule[] = [];
  for (const module of modules) {
    if (!includes.find((e) => e._id === module._id)) {
      result.push(module);
    }
  }

  return result;
};

// function useTemplateName(
//   menuTemplates: IMenuTemplate[],
//   field: FieldInputProps<any>
// ) {
//   let tempName: string | undefined;

//   if (!field.value || field.value === 0) {
//     tempName = menuTemplates.find((e) => e.id === 0)?.title;
//   } else {
//     tempName = menuTemplates.find((e) => e.id === field.value)?.title;
//   }

//   return tempName;
// }

export default TraininAddModule;
