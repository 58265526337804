import React, { useState } from 'react';

import TabsControl, { ITab } from '../../app/components/controls/TabsControl';
import DevicesEmptyState from '../../app/components/devices/DevicesEmptyState';
import DeviceStatus from '../../app/components/devices/DeviceStatus';
import PingVrIcons from '../../app/components/icons/PingVrIcons';
import VrIcon from '../../app/components/icons/VrIcon';
import DeleteModal from '../../app/components/layout/DeleteModal';
import LoadingSpinner from '../../app/components/layout/LoadingSpinner';
import useDevicesStatus from '../../app/hooks/useDevicesStatus';
import usePropterUtils from '../../app/hooks/usePropterUtils';
import { IDevice } from '../../app/models';

const DevicesPage: React.FC = () => {
  const { isLoading, activeDevice, inactiveDevice, deleteMutation } =
    useDevicesStatus();
  const [active, setActive] = useState('0');
  const tabs = useCreateTabs(active, activeDevice);
  const [openModal, setOpenModal] = useState(false);
  const [deletedId, setDeleteId] = useState('');

  return (
    <>
      <DeleteModal
        isOpenModal={openModal}
        onClose={() => {
          setDeleteId('');
          setOpenModal(false);
        }}
        onContinue={() => {
          setOpenModal(false);
          deleteMutation.mutateAsync(deletedId);
          setDeleteId('');
        }}
      />
      <div className='px-4 sm:px-6 lg:px-8 mt-6'>
        <TabsControl
          active={active}
          setActive={setActive}
          tabs={tabs}
          defaultActive={'0'}
        >
          {isLoading && <LoadingSpinner />}
          {activeDevice.length === 0 && active === '0' && <DevicesEmptyState />}
          {active === '0' &&
            activeDevice.map((e) => {
              return (
                <DeviceStatus
                  key={e._id}
                  device={e}
                  onDeleteClick={(id) => {
                    setDeleteId(id);
                    setOpenModal(true);
                  }}
                />
              );
            })}
          {inactiveDevice &&
            active === '1' &&
            inactiveDevice.map((e) => {
              return (
                <DeviceStatus
                  key={e._id}
                  device={e}
                  onDeleteClick={(id) => {
                    setDeleteId(id);
                    setOpenModal(true);
                  }}
                />
              );
            })}
        </TabsControl>
      </div>
    </>
  );
};

const useCreateTabs = (active: string, activeDevice: IDevice[]) => {
  const { classNames } = usePropterUtils();

  const activeIcon =
    activeDevice.length > 0 ? (
      <PingVrIcons
        className='flex justify-center mr-2'
        iconColor='text-green-500'
        pingColor='text-green-400'
        size='w-5 h-5'
      />
    ) : (
      <VrIcon
        className={classNames(
          active === '0'
            ? 'text-green-500'
            : 'text-green-400 group-hover:text-green-500',
          '-ml-0.5 mr-2 h-5 w-5'
        )}
      />
    );

  return [
    {
      value: '0',
      text: 'Active devices',
      icon: activeIcon,
    },
    {
      value: '1',
      text: 'Inactive devices',
      icon: (
        <VrIcon
          className={classNames(
            active === '1'
              ? 'text-yellow-500'
              : 'text-yellow-400 group-hover:text-yellow-500',
            '-ml-0.5 mr-2 h-5 w-5'
          )}
        />
      ),
    },
  ] as ITab[];
};

export default DevicesPage;
