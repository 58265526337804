import React from 'react';
import { useLocation } from 'react-router-dom';
import { IDevice } from '../models';
import { IOrder } from '../models/common/IOrder';

const usePropterUtils = () => {
  const reorder = (list: IOrder[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    result.forEach((e, i) => {
      if (e) {
        e.order = i;
      }
    });

    return result;
  };

  const classNames = (...classes: string[]): string => {
    return classes.filter(Boolean).join(' ');
  };

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const isActiveDevice = (device: IDevice) => {
    if (
      (new Date(new Date().toISOString()) as any) -
        (new Date(device.lastStatusTime) as any) <
      10000
    ) {
      return true;
    } else {
      return false;
    }
  };

  return {
    reorder,
    classNames,
    useQuery,
    isActiveDevice,
  };
};

export default usePropterUtils;
