import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ReportsPage from './ReportsPage';
import ReportPage from './ReportPage';

const ReportPages: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<ReportsPage />} />
      <Route path='/report' element={<ReportPage />} />
    </Routes>
  );
};

export default ReportPages;
