import { useMutation, useQuery, useQueryClient } from 'react-query';

import { SignUp, IUserChange } from '../models/forms';
import agent from '../helpers/agent';
import useShowMessage from './useShowMessage';

const useUsers = () => {
  const { successMessage, errorMessage } = useShowMessage();
  const { isLoading, isError, data, error } = useQuery(
    'users',
    agent.Users.getList,
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );

  const queryClient = useQueryClient();

  const postMutation = useMutation(
    async (createUser: SignUp) => {
      return await agent.Users.post(createUser);
    },
    {
      onSuccess: (user) => {
        queryClient.setQueriesData(['users'], [...(data ? data : []), user]);
        successMessage('Create users success');
      },
      onError: () => {
        errorMessage('Create users error');
      },
    }
  );

  const putMutation = useMutation(
    async (user: IUserChange) => {
      const update = await agent.Users.put(user._id!, user);
      const newData = data ? data.filter((e) => e._id !== update._id) : [];
      return [...newData, update];
    },
    {
      onSuccess: (users) => {
        queryClient.setQueryData(['users'], users);
        successMessage('Update user success');
      },
      onError: () => {
        errorMessage('Update user error');
      },
    }
  );

  const deleteMutation = useMutation(
    async (id: string) => {
      await agent.Users.delete(id);
      return data?.filter((e) => e._id !== id);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['users'], data);
        successMessage('Delete user success');
      },
      onError: () => {
        errorMessage('Delete user error');
      },
    }
  );

  return {
    users: data,
    error,
    isError,
    isLoading,
    postMutation,
    putMutation,
    deleteMutation,
  };
};

export default useUsers;
