import React, { useState } from 'react';

import { FieldMetaProps, useField } from 'formik';
import { EyeOffIcon, EyeIcon, LockClosedIcon } from '@heroicons/react/outline';

import ValidationError from './ValidationError';

interface Props {
  label: string;
  name: string;
}

const PasswordInput: React.FC<Props> = ({ label, name }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const [field, meta] = useField(name);

  const inputClass = createInputCss(meta);

  return (
    <div className='relative mb-4'>
      <label
        htmlFor={name}
        className='block pl-1 text-xs font-semibold text-gray-400'
      >
        {label}
      </label>
      <div className='relative rounded-md shadow-sm'>
        <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-[#005CB4]'>
          <LockClosedIcon className='h-6 w-6' />
        </div>

        <input
          className={inputClass}
          type={showPassword ? 'text' : 'password'}
          placeholder={label}
          {...field}
        />

        <button
          type='button'
          className='inline-block items-center justify-center absolute right-1 top-0 h-full w-10 text-[#005CB4] focus:none'
          onClick={(e) => setShowPassword(!showPassword)}
          tabIndex={-1}
        >
          {showPassword ? (
            <EyeIcon className='h-6 w-6' />
          ) : (
            <EyeOffIcon className='h-6 w-6' />
          )}
        </button>
      </div>
      <ValidationError touched={meta.touched} error={meta.error} />
    </div>
  );
};

const createInputCss = (meta: FieldMetaProps<any>): string => {
  let inputClass = '';

  if (meta.touched && meta.error) {
    inputClass =
      'block w-full pl-10 p-3 sm:text-base rounded-md focus:outline-none border focus:border-[#005CB4] border-red-300';
  } else {
    inputClass =
      'block w-full pl-10 p-3 sm:text-base rounded-md focus:outline-none border focus:border-[#005CB4] border-gray-300';
  }

  return inputClass;
};

export default PasswordInput;
