import React from 'react';

const Home: React.FC = () => {
  return (
    <div className='flex justify-center items-center pt-[5%]'>
      <img src='../assets/images/logo.png' alt='Pepsico' className='w-[50%]' />
    </div>
  );
};

export default Home;
