import { format } from 'date-fns';
import { sr } from 'date-fns/locale';

export const getLocaleTime = (date: Date) => {
  if (!date) {
    return '';
  }

  return format(new Date(date), 'p', { locale: sr });
};
