import React from 'react';

interface Props {
  text: string;
  onClick: () => void;
}

const SmallButton: React.FC<Props> = ({ text, onClick }: Props) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className='inline-flex bg-slate-300 items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-800 sm:text-sm'
    >
      {text}
    </button>
  );
};

export default SmallButton;
