import { FormikErrors, isObject } from 'formik';
import isEmpty from 'validator/lib/isEmpty';
import { ILanguage } from '../language';
import { IModule } from '../module';
import { ITraining } from '../training';

export interface IProgress {
  module: IModule;
  time: number;
  quizResult: string;
}

export interface IStartTrainingForm {
  deviceId: string;
  deviceName: string;
  training: ITraining | null;
  language: ILanguage | null;
  employeeId: string;
  employeeName: string;
}

export function createTrainingEvent(
  deviceId: string,
  deviceName: string
): IStartTrainingForm {
  return {
    deviceId,
    deviceName,
    training: null,
    language: { code: 'sr', name: 'Serbian', nativeName: 'српски језик' },
    employeeId: '',
    employeeName: '',
  };
}

export function validateStartTrainingForm(
  values: IStartTrainingForm
): FormikErrors<IStartTrainingForm> {
  const errors: FormikErrors<IStartTrainingForm> = {};

  if (!isObject(values.training)) errors.training = 'required';
  if (isEmpty(values.employeeId)) errors.employeeId = 'required';
  if (isEmpty(values.employeeName)) errors.employeeName = 'required';
  if (!isObject(values.language)) errors.language = 'required';

  return errors;
}
