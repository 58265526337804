import React from 'react';

import { useField } from 'formik';

import Select from 'react-select';

import { getSelectedStyles, selectTheme } from '../../../styles/styles';
import ValidationError from './ValidationError';
import { IRole } from '../../models';

interface Props {
  label: string;
  name: string;
  className?: string;
}

const RoleSelect: React.FC<Props> = ({ label, name, className }) => {
  const [field, meta, helpers] = useField(name);

  const roles: IRole[] = [
    {
      role: 'operator',
      text: 'Operator',
    },
    {
      role: 'admin',
      text: 'Admin',
    },
  ];

  const styles = getSelectedStyles(meta);

  return (
    <div className={className + ' sm:text-sm mb-2'}>
      <label
        htmlFor='roles'
        className='block pl-1 text-xs font-semibold text-gray-400'
      >
        {label}
      </label>
      <Select
        id='rolse-select'
        onBlur={() => helpers.setTouched(true)}
        theme={selectTheme}
        styles={styles}
        getOptionValue={(value) => value.role}
        getOptionLabel={(value) => value.text}
        menuPlacement='auto'
        menuPosition='fixed'
        options={roles}
        placeholder={label}
        instanceId={`${name}-select`}
        className='border text-gray-400'
        onChange={(value) => helpers.setValue(value)}
        value={field.value}
      />
      <ValidationError touched={meta.touched} error={meta.error} />
    </div>
  );
};

export default RoleSelect;
