import React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const FormRow: React.FC<Props> = ({ children, className }) => {
  const classes = className ? className : 'grid md:grid-cols-12 md:gap-2';

  return <div className={classes}>{children}</div>;
};

export default FormRow;
