import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ITraining } from '../models';
import agent from '../helpers/agent';
import useShowMessage from './useShowMessage';
import { queryKeys } from '../react-query/constants';

const useTrainings = () => {
  const { successMessage } = useShowMessage();
  const { isLoading, data = [] } = useQuery(
    queryKeys.trainings,
    agent.Trainings.getList
  );

  const queryClient = useQueryClient();

  const postMutation = useMutation(
    (newTraining: ITraining) => agent.Trainings.post(newTraining),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.trainings);
        successMessage('Create training success');
      },
    }
  );

  const putMutation = useMutation(
    (training: ITraining) => agent.Trainings.put(training),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.trainings);
        successMessage('Update training success');
      },
    }
  );

  const deleteMutation = useMutation(
    (id: string) => agent.Trainings.delete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.trainings);
        successMessage('Delete training success');
      },
    }
  );

  return {
    trainings: data,
    isLoading,
    postMutation,
    putMutation,
    deleteMutation,
  };
};

export default useTrainings;
