import React from 'react';
import { Form, Formik } from 'formik';

import FormRow from '../layout/FormRow';
import FormTitle from '../layout/FormTitle';
import TextInput from '../controls/TextInput';
import SubmitButton from '../controls/SubmitButton';
import { ITraining } from '../../models';
import { createTrainingForm, validateTraining } from '../../models/forms';
import useTrainings from '../../hooks/useTrainings';
import { useNavigate } from 'react-router-dom';
import CloseButton from '../controls/CloseButton';
import TraininAddModule from './TrainingIncludeModule';
import useModule from '../../hooks/useModule';
import { DropResult } from 'react-beautiful-dnd';
import DndContext from '../common/DndContext';
import DroppableModules from '../modules/DroppableModules';
import { IModule } from '../../models/module';
import usePropterUtils from '../../hooks/usePropterUtils';

interface Props {
  training?: ITraining | null;
}

const TrainingForm: React.FC<Props> = ({ training }: Props) => {
  const navigate = useNavigate();
  const { reorder } = usePropterUtils();
  const { putMutation, postMutation } = useTrainings();
  const { modules } = useModule();

  return (
    <>
      <FormTitle title='TRAINING' rightButton={<CloseButton />} />
      <Formik
        initialValues={createTrainingForm(training)}
        validate={(values) => validateTraining(values)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          if (training && training._id) {
            const update = { ...training, ...values };
            putMutation.mutate(update);
            resetForm({ values });
            navigate(-1);
          } else {
            const newTraining = { ...values } as ITraining;
            postMutation.mutate(newTraining);
            resetForm({ values });
            navigate(-1);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, dirty, isValid, setFieldValue }) => (
          <Form>
            <div className='max-w-xl'>
              <FormRow>
                <TextInput
                  name='name'
                  label='Training name'
                  className='md:col-span-12'
                />
              </FormRow>

              <div>
                {modules && (
                  <TraininAddModule
                    modules={modules}
                    includedModule={values ? values.modules : []}
                    onSelect={(item: IModule) => {
                      setTimeout(() => {
                        item.order = values.modules.length + 1;
                        setFieldValue('modules', [...values.modules, item]);
                      }, 300);
                    }}
                  />
                )}
              </div>

              <DndContext
                onDragEnd={(result: DropResult) => {
                  if (!result.destination) {
                    return;
                  }

                  const list = reorder(
                    values.modules,
                    result.source.index,
                    result.destination.index
                  ) as IModule[];

                  setFieldValue('modules', list);
                }}
              >
                {
                  <DroppableModules
                    modules={values ? values.modules : []}
                    onClickExclude={(id) => {
                      const newState = [
                        ...values.modules.filter((e) => e._id !== id),
                      ];
                      setFieldValue('modules', newState);
                    }}
                  />
                }
              </DndContext>

              <div className='pb-5'>
                <SubmitButton
                  isLoading={isSubmitting}
                  text='Submit'
                  widthFull={false}
                  disabled={isSubmitting || !dirty || !isValid}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TrainingForm;
