import jwt_decode from 'jwt-decode';

import { Signin } from '../models/forms';
import agent from '../helpers/agent';

const useAuth = () => {
  const signin = async (signin: Signin) => {
    try {
      const result = await agent.Auth.signIn(signin);

      if (!result || result.status !== 200) {
        return Promise.reject('Credential error');
      }

      localStorage.setItem('jwt', result.token);

      return result.token;
    } catch (error) {
      return Promise.reject('Credential error');
    }
  };

  const signout = () => {
    localStorage.removeItem('jwt');
  };

  const isLogin = () => {
    const token = localStorage.getItem('jwt');

    if (!token) return false;

    const decoded: any = jwt_decode(token);

    if (Date.now() >= decoded.exp * 1000) {
      return false;
    }

    return true;
  };

  const getRoleFromToken = () => {
    const token = localStorage.getItem('jwt');

    if (!token) return null;

    const decoded: any = jwt_decode(token);

    if (!decoded.role) {
      return null;
    }

    return decoded.role.role;
  };

  const getUserNameFormToken = (): string | null => {
    const token = localStorage.getItem('jwt');

    if (!token) return null;

    const decoded: any = jwt_decode(token);

    if (!decoded.name) {
      return null;
    }

    return decoded.name;
  };

  return {
    signin,
    signout,
    isLogin,
    getRoleFromToken,
    getUserNameFormToken,
  };
};

export default useAuth;
