import React from 'react';

import { Route, Routes } from 'react-router';

import { QueryClientProvider } from 'react-query';
import { queryClient } from '../app/react-query/query-client';
import { ReactQueryDevtools } from 'react-query/devtools';
import Container from '../app/components/layout/Container';

import AppPages from './AppPages';

const AuthPages = React.lazy(() => import('./auth/AuthPages'));

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Container>
        <Routes>
          <Route path='/auth/*' element={<AuthPages />} />
          <Route path='/*' element={<AppPages />} />
        </Routes>
      </Container>
      {process.env.NODE_ENV !== 'production' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      <div id='notification'></div>
      <div id='modal-root'></div>
    </QueryClientProvider>
  );
};

export default App;
