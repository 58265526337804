import React from 'react';
import ShowMessage from '../../app/components/controls/ShowError';

import LoadingSpinner from '../../app/components/layout/LoadingSpinner';
import UsersTable from '../../app/components/users/UsersTable';
import useUsers from '../../app/hooks/useUsers';

const UsersPage: React.FC = () => {
  const { users, isLoading, isError } = useUsers();

  return (
    <>
      {isLoading && <LoadingSpinner />}

      {users && !isLoading && isError && <ShowMessage />}
      {users && !isLoading && !isError && <UsersTable users={users} />}
    </>
  );
};

export default UsersPage;
