import React from 'react';

import { FieldMetaProps, useField } from 'formik';
import ValidationError from './ValidationError';

interface Props {
  name: string;
  label: string;
  className?: string;
}

const TextAreaInput: React.FC<Props> = ({ name, label, className }: Props) => {
  const [filed, meta] = useField(name);

  const inputClass = createInputCss(meta);

  return (
    <div className={className + ' mb-4'}>
      <label
        htmlFor={name}
        className='block pl-1 text-xs font-semibold text-gray-400'
      >
        {label}
      </label>
      <div className='relative rounded-md shadow-sm'>
        <textarea
          id={name}
          className={inputClass}
          placeholder={label}
          autoComplete='off'
          {...filed}
          value={filed.value}
        ></textarea>
      </div>
      <ValidationError touched={meta.touched} error={meta.error} />
    </div>
  );
};

const createInputCss = (meta: FieldMetaProps<any>): string => {
  let inputClass = '';

  if (meta.touched && meta.error) {
    inputClass =
      'block w-full p-3 sm:text-sm rounded-md focus:outline-none border focus:border-[#005CB4] border-red-300';
  } else {
    inputClass =
      'block w-full p-3 sm:text-sm rounded-md focus:outline-none border focus:border-[#005CB4] border-gray-300';
  }

  return inputClass;
};

export default TextAreaInput;
