import React from 'react';

import { useField } from 'formik';
import Select from 'react-select';
import { getSelectedStyles, selectTheme } from '../../../styles/styles';

import ValidationError from './ValidationError';
import { ILanguage } from '../../models/language';

interface Props {
  label: string;
  name: string;
  className?: string;
}

const LanguageSelect: React.FC<Props> = ({ name, label, className }: Props) => {
  const [field, meta, helpers] = useField(name);

  const languages: ILanguage[] = [
    { code: 'sr', name: 'Serbian', nativeName: 'српски језик' },
    { code: 'en', name: 'English', nativeName: 'English' },
  ];

  const styles = getSelectedStyles(meta);

  return (
    <div className={className + ' mb-2'} autoCorrect='off'>
      <label
        htmlFor='translates'
        className='block pl-1 text-xs font-semibold text-gray-400'
      >
        {label}
      </label>
      <Select
        id='translates'
        theme={selectTheme}
        styles={styles}
        getOptionValue={(value) => value}
        getOptionLabel={(value) => value.name}
        menuPlacement='auto'
        menuPosition='fixed'
        options={languages}
        placeholder={label}
        instanceId={`${name}-select`}
        className='border text-gray-400'
        onChange={(values) => helpers.setValue(values)}
        onBlur={() => helpers.setTouched(true)}
        value={field.value}
      />
      <ValidationError touched={meta.touched} error={meta.error} />
    </div>
  );
};

export default LanguageSelect;
