import React from 'react';

import { Route, Routes } from 'react-router-dom';

import UserPage from './UserPage';
import UsersPage from './UsersPage';

const UsersPages: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<UsersPage />} />
      <Route path='/:id' element={<UserPage />} />
    </Routes>
  );
};

export default UsersPages;
