import { format } from 'date-fns';
import { sr } from 'date-fns/locale';

export const getLocaleDate = (date: Date) => {
  if (!date) {
    return '';
  }

  const browserLanguage = navigator.language;

  if (browserLanguage === 'sr') {
    return format(new Date(date), 'P', { locale: sr });
  }

  return format(new Date(date), 'P');
};
