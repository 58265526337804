import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ModulesPage from './ModulesPage';
import ModulePage from './ModulePage';

const ModuleRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<ModulesPage />} />
      <Route path='/:id' element={<ModulePage />} />
    </Routes>
  );
};

export default ModuleRoutes;
