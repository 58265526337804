import React from 'react';

import { FieldMetaProps, Form, Formik, useField } from 'formik';
import isEmpty from 'validator/lib/isEmpty';

import useDevicesStatus from '../../hooks/useDevicesStatus';
import SmallButton from '../controls/SmallButton';
import SmallSubmitButton from '../controls/SmallSubmitButton';
import { IDevice } from '../../models';

interface Props {
  device: IDevice;
  onClickClose: () => void;
}

const InlineNameForm: React.FC<Props> = ({ device, onClickClose }) => {
  const { renameDevice } = useDevicesStatus();
  return (
    <Formik
      initialValues={{ name: '' }}
      validate={(values) => {
        const errors: any = {};
        if (isEmpty(values.name)) errors.name = 'required';
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        await renameDevice(device.deviceId, values.name);
        setSubmitting(false);
        onClickClose();
      }}
    >
      {({ isSubmitting, dirty, isValid, values }) => (
        <Form>
          <div className='flex w-full items-center gap-1'>
            <InlineInput name='name' placeholder='Enter new device name' />
            <SmallSubmitButton
              isLoading={isSubmitting}
              text='Save'
              disabled={isSubmitting || !dirty || !isValid}
            />
            <SmallButton text='Cancel' onClick={onClickClose} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

interface PropsInputInline {
  name: string;
  placeholder: string;
}

const InlineInput: React.FC<PropsInputInline> = ({
  name,
  placeholder,
}: PropsInputInline) => {
  const [filed, meta] = useField(name);
  const inputClass = createInputCss(meta);

  return (
    <div>
      <div className='flex rounded-md mx-1 shadow-sm'>
        <input
          autoFocus={true}
          id={name}
          className={inputClass}
          type='text'
          placeholder={placeholder}
          autoComplete='off'
          {...filed}
          value={filed.value}
        />
      </div>
    </div>
  );
};

const createInputCss = (meta: FieldMetaProps<any>): string => {
  let inputClass = '';

  if (meta.touched && meta.error) {
    inputClass =
      'block w-full p-2 sm:text-sm rounded-md focus:outline-none border focus:border-[#005CB4] border-red-300';
  } else {
    inputClass =
      'block w-full p-2 sm:text-sm rounded-md focus:outline-none border focus:border-[#005CB4] border-gray-300';
  }

  return inputClass;
};

export default InlineNameForm;
