import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './features/App';

import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import './i18n';
import LoadingSpinner from './app/components/layout/LoadingSpinner';

export const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <HistoryRouter history={history}>
      <Suspense fallback={<LoadingSpinner />}>
        <App />
      </Suspense>
    </HistoryRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
