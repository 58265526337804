import { FormikErrors } from 'formik';

import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isStrongPassword from 'validator/lib/isStrongPassword';
import { IRole, IUser } from '../user';

export interface SignUp {
  name: string;
  password: string;
  confirmPassword: string;
  role: IRole;
}

export const validateSignUp = (
  values: SignUp,
  user: IUser | null | undefined
): FormikErrors<SignUp> => {
  const errors: any = {};

  if (isEmpty(values.name)) errors.name = 'required';

  if (!errors.name && !isEmail(values.name)) errors.name = 'email';

  if (!user && isEmpty(values.password)) {
    errors.password = 'required';
  }

  const optionsPassword = {
    minLength: 6,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 0,
    returnScore: false,
    pointsPerUnique: 0,
    pointsPerRepeat: 0,
    pointsForContainingLower: 0,
    pointsForContainingUpper: 0,
    pointsForContainingNumber: 0,
    pointsForContainingSymbol: 0,
  };

  if (
    !user &&
    !isEmpty(values.password) &&
    !isStrongPassword(values.password, optionsPassword)
  ) {
    errors.password = 'complexPassword';
  }

  if (!user && isEmpty(values.confirmPassword)) {
    errors.confirmPassword = 'required';
  }

  if (!user && isEmpty(values.confirmPassword)) {
    errors.confirmPassword = 'required';
  }

  if (
    !user &&
    !isEmpty(values.confirmPassword) &&
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = 'confirmPassword';
  }

  if (!values.role) errors.role = 'required';
  return errors;
};

export const createSignUp = (user?: IUser | null): SignUp => {
  return {
    name: user ? user.name : '',
    password: '',
    confirmPassword: '',
    role: user
      ? user.role
      : {
          role: 'operator',
          text: 'Operator',
        },
  };
};
