import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getLocaleDate } from '../../helpers/localDate';
import { getLocaleTime } from '../../helpers/localTime';
import useAuth from '../../hooks/useAuth';
import useReports from '../../hooks/useReports';

import Pagination from '../controls/Pagination';
import DeleteModal from '../layout/DeleteModal';
import TableContainer from '../layout/TableContainer';
import TableHead from '../layout/TableHead';
import TablePageHeader from '../layout/TablePageHeader';

const ReportsTable: React.FC = () => {
  const navigate = useNavigate();

  const {
    reports,
    totalCount,
    deleteMutation,
    nextPage,
    previousPage,
    currentPage,
    isLoading,
    downloadExcel,
  } = useReports();

  const [openModal, setOpenModal] = useState(false);
  const [deletedId, setDeleteId] = useState('');
  const [disableDownload, setDisableDownload] = useState(false);
  const { getUserNameFormToken, getRoleFromToken } = useAuth();
  const userName = getUserNameFormToken();
  const role = getRoleFromToken();

  return (
    <div className='px-4 sm:px-6 lg:px-8 mt-6'>
      <DeleteModal
        isOpenModal={openModal}
        onClose={() => {
          setDeleteId('');
          setOpenModal(false);
        }}
        onContinue={() => {
          setOpenModal(false);
          deleteMutation.mutateAsync(deletedId);
          setDeleteId('');
          if (10 * (currentPage - 1) + 1 >= totalCount) {
            previousPage();
          }
        }}
      />
      <TablePageHeader
        title='Reports'
        subtitle='A list of all trainigs'
        addButtonText='Download excel'
        disabledButton={disableDownload}
        onClickAddButton={async () => {
          setDisableDownload(true);
          await downloadExcel();
          setDisableDownload(false);
        }}
      />
      <TableContainer>
        <TableHead
          headTitles={[
            'Training date',
            'Start time',
            'End time',
            'Employee ID',
            'Name',
            'Training done',
            'Training result',
            'Launched by user',
            'Actions',
          ]}
        />
        <tbody className='divide-y divide-gray-200 bg-white'>
          {!isLoading &&
            reports &&
            reports.map((report, reportIdx) => (
              <tr key={report._id}>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                  {getLocaleDate(report.startTraining)}
                </td>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                  {getLocaleTime(report.startTraining)}
                </td>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                  {getLocaleTime(report.endTraining!)}
                </td>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                  {report.employeeId}
                </td>
                <td className='px-3 py-2 text-sm text-gray-500'>
                  {report.employeeName}
                </td>
                <td className='px-3 py-2 text-sm text-gray-500'>
                  {report.training.name}
                </td>
                <td className='px-3 py-2 text-sm text-gray-500'>
                  {report.quizResult}
                </td>
                <td className='px-3 py-2 text-sm text-gray-500'>
                  {report.user}
                </td>
                <td className='whitespace-nowrap py-2 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'>
                  <button
                    onClick={() =>
                      navigate(
                        `./report?page=${currentPage}&report=${report._id}`
                      )
                    }
                    type='button'
                    className='inline-flex m-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                  >
                    Details
                  </button>
                  {((userName &&
                    userName.length > 0 &&
                    userName
                      .trim()
                      .toLowerCase()
                      .includes(report.user?.trim().toLowerCase())) ||
                    role !== 'operator') && (
                    <button
                      onClick={() => {
                        setDeleteId(report._id!);
                        setOpenModal(true);
                      }}
                      type='button'
                      className='inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                    >
                      Delete
                      <span className='sr-only'>{report.employeeName}</span>
                    </button>
                  )}
                  {reportIdx !== 0 ? (
                    <div className='absolute right-6 left-0 -top-px h-px bg-gray-200' />
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </TableContainer>
      {totalCount > 10 && (
        <Pagination
          totalCount={totalCount}
          curentPage={currentPage}
          onNextPage={nextPage}
          onPrevious={previousPage}
        />
      )}
    </div>
  );
};

export default ReportsTable;
