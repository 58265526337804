import React from 'react';

import { DraggableProvided } from 'react-beautiful-dnd';

import ShowMoreMenu from '../controls/ShowMoreMenu';
import ModuleTileMenu from './ModuleTileMenu';

interface Props {
  name: string;
  id: string;
  provided: DraggableProvided;
  onClickExclude: (id: string) => void;
}

const ModuleTile: React.FC<Props> = ({
  name,
  id,
  provided,
  onClickExclude,
}: Props) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='p-3 rounded-xl my-1 border-[1px]'
    >
      <div className='flex justify-between items-center' onClick={() => {}}>
        <span className='flex font-normal text-[#005CB4]'>{name}</span>

        <ShowMoreMenu
          menuList={<ModuleTileMenu onClickExclude={onClickExclude} id={id} />}
        />
      </div>
    </div>
  );
};

export default ModuleTile;
