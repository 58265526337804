import React from 'react';
import usePropterUtils from '../../hooks/usePropterUtils';

export interface ITab {
  value: string;
  text: string;
  icon?: JSX.Element;
}

interface Props {
  tabs: ITab[];
  defaultActive: string;
  active: string;
  setActive: (active: string) => void;
  children: React.ReactNode;
}

const TabsControl: React.FC<Props> = ({
  tabs,
  active,
  setActive,
  defaultActive,
  children,
}) => {
  const { classNames } = usePropterUtils();

  return (
    <>
      <div className='mb-2'>
        <div className='sm:hidden'>
          <label htmlFor='tabs' className='sr-only'>
            Select a tab
          </label>
          <select
            id='tabs'
            name='tabs'
            onChange={(e) => {
              setActive(e.currentTarget.value);
            }}
            className='block w-full bg-slate-100 border p-2 border-blue-500 rounded-md'
            defaultValue={defaultActive}
          >
            {tabs.map((tab) => (
              <option key={tab.value} value={tab.value}>
                {tab.text}
              </option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block'>
          <div className='border-b-2 border-[#C9002B]'>
            <nav className='-mb-px flex' aria-label='Tabs'>
              {tabs.map((tab) => (
                <button
                  key={tab.value}
                  className={classNames(
                    active === tab.value
                      ? 'border-gray-900  bg-gray-700 text-white'
                      : 'border-transparent bg-slate-100 text-gray-500 hover:text-gray-700 hover:border-[#C9002B]',
                    'group inline-flex items-center py-4 px-10 border-b-2 font-semibold rounded-t-lg text-sm'
                  )}
                  aria-current={active ? 'page' : undefined}
                  onClick={() => {
                    setActive(tab.value);
                  }}
                >
                  {tab.icon}
                  <span>{tab.text}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {children}
    </>
  );
};

export default TabsControl;
