import React, { Fragment } from 'react';

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';

interface Props {
  menuList?: React.ReactNode;
}

const ShowMoreMenu: React.FC<Props> = ({ menuList }) => {
  return (
    <Disclosure
      as='nav'
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {({ open }) => (
        <>
          <div className='flex items-center'>
            <div className='md:ml-4 md:flex-shrink-0 md:flex md:items-center'>
              <Menu as='div' className='ml-3 relative'>
                <div>
                  <Menu.Button className='flex text-sm rounded-full hover:outline-none hover:ring-2 hover:ring-white'>
                    <OpenButton />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-200'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-white ring-opacity-5'>
                    {menuList}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

const OpenButton: React.FC = () => {
  return (
    <div className='inline-flex items-center justify-center p-2 rounded-md text-[#C9002B] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-700'>
      <span className='sr-only '>Open tile menu</span>
      <DotsVerticalIcon className='w-6 h-6' />
    </div>
  );
};

export default ShowMoreMenu;
