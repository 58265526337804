import React from 'react';

interface Props {
  title: string;
  subtitle?: string;
  addButtonText?: string;
  disabledButton?: boolean;
  onClickAddButton?: () => void;
}

const TablePageHeader: React.FC<Props> = ({
  title,
  subtitle,
  addButtonText,
  disabledButton = false,
  onClickAddButton,
}) => {
  return (
    <div className='sm:flex sm:items-center border-accent-color border-b-2 pb-1'>
      <div className='sm:flex-auto'>
        <h1 className='text-xl font-semibold text-gray-900'>{title}</h1>
        <p className='mt-2 text-sm text-gray-700'>{subtitle}</p>
      </div>
      {addButtonText && (
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <button
            onClick={onClickAddButton}
            type='button'
            className='btn-primary'
          >
            {addButtonText}
          </button>
        </div>
      )}
    </div>
  );
};

export default TablePageHeader;
