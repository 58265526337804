import { toast } from 'react-toastify';
// import { AxiosError } from 'axios';

const useShowMessage = () => {
  // const { t } = useTranslation();

  const successMessage = (message: string): void => {
    toast.success(message);
  };

  const errorMessage = (message: string): void => {
    toast.error(message);
  };

  // const errorMessage = (error: AxiosError) => {
  //   if (error.isAxiosError && error?.response) {
  //     const { data } = error.response;
  //     const { message } = data;
  //     if (message) {
  //       switch (message) {
  //         case ServerErrorMessage.Parameters:
  //           toast.error(t('notification:parameters'));
  //           break;
  //         case ServerErrorMessage.SendMail:
  //           toast.error(t('notification:sendMailFaild'));
  //           break;
  //         case ServerErrorMessage.PasswordRequired:
  //           toast.error(t('notification:passwordRequired'));
  //           break;
  //         case ServerErrorMessage.NameRequired:
  //           toast.error(t('notification:nameRequired'));
  //           break;
  //         case ServerErrorMessage.StrongPassword:
  //           toast.error(t('notification:strongPassword'));
  //           break;
  //         case ServerErrorMessage.UserNameIsTaken:
  //           toast.error(t('notification:userNameIsTaken'));
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   }

  // if (typeof error === 'string') {
  //   toast.error(error);
  // }
  // };
  return {
    successMessage,
    errorMessage,
  };
};

export default useShowMessage;
