import React from 'react';
import FormContainer from '../../app/components/common/FormContainer';
import DownloadManual from '../../app/components/manual/DownloadManula';

const ManualPage: React.FC = () => {
  return (
    <FormContainer>
      <DownloadManual />
    </FormContainer>
  );
};

export default ManualPage;
