import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ITraining } from '../../models';
import useTrainings from '../../hooks/useTrainings';
import TablePageHeader from '../layout/TablePageHeader';
import TableContainer from '../layout/TableContainer';
import TableHead from '../layout/TableHead';
import DeleteModal from '../layout/DeleteModal';
import useAuth from '../../hooks/useAuth';

interface Props {
  trainings: ITraining[];
}

const TrainingsTable: React.FC<Props> = ({ trainings }) => {
  const navigate = useNavigate();
  const { deleteMutation } = useTrainings();
  const [openModal, setOpenModal] = useState(false);
  const [deletedId, setDeleteId] = useState('');
  const { getRoleFromToken } = useAuth();
  const role = getRoleFromToken();

  return (
    <div className='px-4 sm:px-6 lg:px-8 mt-6'>
      <DeleteModal
        isOpenModal={openModal}
        onClose={() => {
          setDeleteId('');
          setOpenModal(false);
        }}
        onContinue={() => {
          setOpenModal(false);
          deleteMutation.mutateAsync(deletedId);
          setDeleteId('');
        }}
      />
      <TablePageHeader
        title='TRAININGS'
        subtitle='A list of all trainigs'
        addButtonText={role === 'operator' ? undefined : 'Add training'}
        onClickAddButton={() => navigate('./new')}
      />
      <TableContainer>
        <TableHead
          lastRight={false}
          headTitles={
            role === 'operator'
              ? ['Traning name', 'Included modules']
              : ['Traning name', 'Included modules', 'Actions']
          }
        />
        <tbody className='divide-y divide-gray-200 bg-white'>
          {trainings.map((training, trainingIdx) => (
            <tr key={training._id}>
              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                {training.name}
              </td>
              <td className='px-3 py-4 text-sm text-gray-500'>
                {training.modules &&
                  training.modules.map((m, index) => {
                    return (
                      <span key={index} className='ml-1'>
                        {m.name +
                          (training.modules.length > index + 1 ? ', ' : '')}
                      </span>
                    );
                  })}
              </td>

              {role !== 'operator' && (
                <td className='whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'>
                  <button
                    onClick={() => navigate(`./${training._id}`)}
                    type='button'
                    className='inline-flex m-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                  >
                    Edit
                    <span className='sr-only'>{training.name}</span>
                  </button>
                  <button
                    onClick={() => {
                      setDeleteId(training._id!);
                      setOpenModal(true);
                    }}
                    type='button'
                    className='inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                  >
                    Delete
                    <span className='sr-only'>{training.name}</span>
                  </button>
                  {trainingIdx !== 0 ? (
                    <div className='absolute right-6 left-0 -top-px h-px bg-gray-200' />
                  ) : null}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </TableContainer>
    </div>
  );
};

export default TrainingsTable;
