import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useModule from '../../hooks/useModule';
import { IModule } from '../../models/module';
import DeleteModal from '../layout/DeleteModal';
import TableContainer from '../layout/TableContainer';
import TableHead from '../layout/TableHead';
import TablePageHeader from '../layout/TablePageHeader';

interface Props {
  modules: IModule[];
}

export default function ModulesTable({ modules }: Props) {
  const { deleteMutation } = useModule();
  const [openModal, setOpenModal] = useState(false);
  const [deletedId, setDeleteId] = useState('');
  const { getRoleFromToken } = useAuth();
  const role = getRoleFromToken();

  const navigate = useNavigate();
  return (
    <>
      <DeleteModal
        isOpenModal={openModal}
        onClose={() => {
          setDeleteId('');
          setOpenModal(false);
        }}
        onContinue={() => {
          setOpenModal(false);
          deleteMutation.mutateAsync(deletedId);
          setDeleteId('');
        }}
      />
      <div className='px-4 sm:px-6 lg:px-8 mt-6'>
        <TablePageHeader
          title='MODULES'
          subtitle='A list of all modules'
          addButtonText={role === 'operator' ? undefined : 'Add module'}
          onClickAddButton={() => navigate('./new')}
        />
        <TableContainer>
          <TableHead
            lastRight={role === 'operator' ? false : true}
            headTitles={
              role === 'operator'
                ? ['Module name', 'Module description']
                : ['Module name', 'Module description', 'Actions']
            }
          />
          <tbody className='divide-y divide-gray-200 bg-white'>
            {modules.map((module, modelIdx) => (
              <tr key={module._id}>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                  {module.name}
                </td>
                <td className='px-3 py-4 text-sm text-gray-500'>
                  {module.description}
                </td>

                {role !== 'operator' && (
                  <td className='whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'>
                    <button
                      onClick={() => navigate(`./${module._id}`)}
                      type='button'
                      className='inline-flex m-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                    >
                      Edit
                      <span className='sr-only'>{module.name}</span>
                    </button>
                    <button
                      onClick={() => {
                        setDeleteId(module._id!);
                        setOpenModal(true);
                      }}
                      type='button'
                      className='inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                    >
                      Delete
                      <span className='sr-only'>{module.name}</span>
                    </button>
                    {modelIdx !== 0 ? (
                      <div className='absolute right-6 left-0 -top-px h-px bg-gray-200' />
                    ) : null}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </TableContainer>
      </div>
    </>
  );
}
