import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAdmin from '../app/components/common/RequireAdmin';

import RequireAuth from '../app/components/common/RequireAuth';
import AppLayout from '../app/components/layout/AppLayout';
import DevicesPage from './devices/DevicesPage';
import HomePage from './home/HomePage';
import ManualPage from './manual/ManualPage';
import ModuleRoutes from './modules/Routes';
import ReportPages from './reports/ReportsPages';
import TrainingRoutes from './trainings/Routes';
import UsersPages from './users/UsersPages';

const AppPages: React.FC = () => {
  return (
    <RequireAuth>
      <AppLayout>
        <Routes>
          <Route path='/*' element={<HomePage />} />
          <Route path='/devices/*' element={<DevicesPage />} />
          <Route path='/reports/*' element={<ReportPages />} />
          <Route path='/trainings/*' element={<TrainingRoutes />} />
          <Route path='/modules/*' element={<ModuleRoutes />} />
          <Route path='/manual' element={<ManualPage />} />
          <Route
            path='/users/*'
            element={
              <RequireAdmin>
                <UsersPages />
              </RequireAdmin>
            }
          />
        </Routes>
      </AppLayout>
    </RequireAuth>
  );
};

export default AppPages;
