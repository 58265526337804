import React from 'react';
import Home from '../../app/components/home/Home';

const HomePage: React.FC = () => {
  return (
    <>
      <div className='px-4 sm:px-6 lg:px-8 mt-6'>
        <Home />
      </div>
    </>
  );
};

export default HomePage;
