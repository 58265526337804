import React from 'react';

import { useParams } from 'react-router-dom';
import isMongoId from 'validator/lib/isMongoId';

import useModule from '../../app/hooks/useModule';
import ModuleForm from '../../app/components/modules/ModuleForm';
import FormContainer from '../../app/components/common/FormContainer';
import { IModule } from '../../app/models/module';

const ModulePage: React.FC = () => {
  const { modules, isLoading } = useModule();
  const { id } = useParams();

  let module: IModule | undefined;

  if (isMongoId(id ? id : '') && !isLoading) {
    module = modules ? modules.find((e) => e._id === id) : undefined;
  } else {
    module = undefined;
  }

  return (
    <>
      <FormContainer>
        {!isLoading && <ModuleForm module={module} />}
      </FormContainer>
    </>
  );
};

export default ModulePage;
