import React from 'react';

interface Props {
  children: React.ReactNode;
}

const FormContainer: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <div className='bg-white mt-6 mb-3  md:full-height overflow-hidden overflow-y-auto px-10 content-height'>
        {children}
      </div>
    </>
  );
};

export default FormContainer;
