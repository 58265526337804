import React from 'react';
import { Form, Formik } from 'formik';

import FormRow from '../layout/FormRow';
import FormTitle from '../layout/FormTitle';
import TextInput from '../controls/TextInput';
import SubmitButton from '../controls/SubmitButton';
import TextAreaInput from '../controls/TextAreaInput';
import { createModuleForm, IModule, validateModule } from '../../models/module';
import useModule from '../../hooks/useModule';
import { useNavigate } from 'react-router-dom';
import CloseButton from '../controls/CloseButton';

interface Props {
  module?: IModule | null;
}

const ModuleForm: React.FC<Props> = ({ module }: Props) => {
  const { postMutation, putMutation } = useModule();
  const navigate = useNavigate();

  return (
    <>
      <FormTitle title='Module' rightButton={<CloseButton />} />
      <Formik
        initialValues={createModuleForm(module)}
        validate={(values) => validateModule(values)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          if (module && module._id) {
            const update = { ...module, ...values } as IModule;
            await putMutation.mutateAsync(update);
            resetForm({ values });
            navigate(-1);
          } else {
            const newModule = { ...values, order: 0 } as IModule;
            await postMutation.mutateAsync(newModule);
            navigate(-1);
            resetForm({ values });
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form>
            <div className='max-w-xl'>
              <FormRow>
                <TextInput
                  name='name'
                  label='Module name'
                  className='md:col-span-12'
                />
              </FormRow>
              <FormRow>
                <TextAreaInput
                  name='description'
                  label='Description'
                  className='md:col-span-12'
                />
              </FormRow>
            </div>
            <div className='pb-5'>
              <SubmitButton
                isLoading={isSubmitting}
                text='Submit'
                widthFull={false}
                disabled={isSubmitting || !dirty || !isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ModuleForm;
