import React from 'react';

const LoadingSpinner: React.FC = () => {
  return (
    <div className='flex items-center justify-center m-16'>
      <div className='w-16 h-16 border-b-2 border-red-400 rounded-full animate-spin'></div>
    </div>
  );
};

export default LoadingSpinner;
