import React from 'react';
import { saveAs } from 'file-saver';
import silentAgent from '../../helpers/silentAgent';

const DownloadManual: React.FC = () => {
  const downloadManual = async () => {
    const res = await silentAgent.Downloads.getManual();

    var blob = new Blob([res], {
      type: 'application/pdf;charset=utf-8',
    });

    saveAs(blob, 'manual.pdf');
  };

  return (
    <div className='mt-5'>
      <h1 className='text-2xl mb-3'>Manual for TRAINING VR PORTAL</h1>
      <button
        onClick={downloadManual}
        type='button'
        className='btn-primary ml-0'
      >
        Download Manual
      </button>
    </div>
  );
};

export default DownloadManual;
