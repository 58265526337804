import React from 'react';

interface Props {
  totalCount: number;
  curentPage: number;
  onNextPage: () => void;
  onPrevious: () => void;
}

const Pagination: React.FC<Props> = ({
  totalCount,
  curentPage,
  onNextPage,
  onPrevious,
}: Props) => {
  const startNumber = 10 * (curentPage - 1) + 1;
  const endNumber = 10 * curentPage > totalCount ? totalCount : 10 * curentPage;
  const disableNext = 10 * curentPage >= totalCount;
  const disablePrevious = curentPage === 1;

  return (
    <nav
      className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'
      aria-label='Pagination'
    >
      <div className='hidden sm:block'>
        <p className='text-sm text-gray-700'>
          Showing <span className='font-medium'>{startNumber}</span> to{' '}
          <span className='font-medium'>{endNumber}</span> of{' '}
          <span className='font-medium'>{totalCount}</span> results
        </p>
      </div>
      <div className='flex-1 flex justify-between sm:justify-end'>
        <button
          onClick={onPrevious}
          disabled={disablePrevious}
          className='btn-primary'
        >
          Previous
        </button>
        <button
          onClick={onNextPage}
          disabled={disableNext}
          className='btn-primary'
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
