import { Transition } from '@headlessui/react';
import {
  ArrowNarrowRightIcon,
  ClockIcon,
  PencilAltIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import usePropterUtils from '../../hooks/usePropterUtils';
import { IDevice } from '../../models';
import DeviceForm from './DeviceForm';
import InlineNameForm from './InlineNameForm';

interface Props {
  device: IDevice;
  onDeleteClick: (id: string) => void;
}

const DeviceStatus: React.FC<Props> = ({ device, onDeleteClick }) => {
  const details = useStatusDetails(device);
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Transition
      className='bg-blue-50 sm:rounded-lg mb-2'
      show={show}
      enter='transition-opacity duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-150'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className='px-4 py-5 sm:p-6'>
        <div className='sm:flex sm:items-center border-[#C9002B] border-b-2 pb-1'>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>
            {device.deviceName ? device.deviceName : device.deviceId}
          </h3>
          {!editMode && (
            <div className='flex justify-center items-center'>
              <EditButton
                onClick={() => {
                  setEditMode(true);
                }}
              />
              <DeleteButton onClick={() => onDeleteClick(device._id)} />
            </div>
          )}
          {editMode && (
            <div className='flex justify-center items-center'>
              <ArrowNarrowRightIcon className='ml-2 w-3 h-3' />
              <InlineNameForm
                device={device}
                onClickClose={() => {
                  setEditMode(false);
                }}
              />
            </div>
          )}
        </div>
        {details}
      </div>
    </Transition>
  );
};

const useStatusDetails = (device: IDevice) => {
  const statusText = useStatusText(device);
  const { isActiveDevice } = usePropterUtils();

  if (!isActiveDevice(device)) {
    return (
      <div className='mt-2 max-w-xl text-sm text-gray-500'>
        Status:
        <strong className='ml-2 inline-flex items-center'>
          {statusText}
          <span className='mx-2 relative h-3 w-3'>
            <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-300 opacity-75'></span>
            <span className='absolute rounded-full h-3 w-3 bg-gray-400'></span>
          </span>
        </strong>{' '}
      </div>
    );
  }

  if (device.lastStatus === 'onhold' && !device.shouldStartTraining) {
    return (
      <div className='max-w-sm mt-4'>
        <DeviceForm device={device} />
      </div>
    );
  }

  if (
    device.lastStatus === 'onhold' &&
    device.shouldStartTraining &&
    isActiveDevice(device)
  ) {
    return (
      <div className='mt-2 max-w-xl text-sm text-gray-500'>
        Status: <strong>{statusText}</strong>
        <div className='flex justify-start align-middle items-center text-red-300 mt-2'>
          <span>I'm waiting for the device</span>{' '}
          <ClockIcon className='animate-spin h-4 w-4 ml-2' />
        </div>
      </div>
    );
  }

  if (device.lastStatus === 'training_in_progress' && isActiveDevice(device)) {
    return (
      <div className='mt-2 max-w-xl text-sm text-gray-500'>
        Status:
        <strong className='ml-2 inline-flex items-center'>
          {statusText}
          <span className='mx-2 relative h-3 w-3'>
            <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75'></span>
            <span className='absolute rounded-full h-3 w-3 bg-green-500'></span>
          </span>
        </strong>{' '}
        Progress:
        <strong> {device.lastScene}</strong> Time Elapsed: <strong>{device.lastTimeElapsed}</strong>
      </div>
    );
  }

  return null;
};

const useStatusText = (device: IDevice) => {
  const { isActiveDevice } = usePropterUtils();

  if (device && !isActiveDevice(device)) {
    return 'Offline';
  }

  if (device && device.lastStatus === 'onhold') {
    return 'On hold';
  }

  if (device && device.lastStatus === 'training_in_progress') {
    return 'Training in progress';
  }
};

interface PropsEditButton {
  onClick: () => void;
}

const EditButton: React.FC<PropsEditButton> = ({ onClick }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className='ml-3 shadow-sm accent-color'
    >
      <PencilAltIcon className='h-4 w-4' aria-hidden='true' />
    </button>
  );
};

interface PropsDeleteButton {
  onClick: () => void;
}

const DeleteButton: React.FC<PropsDeleteButton> = ({ onClick }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className='ml-3 shadow-sm accent-color'
    >
      <TrashIcon className='h-4 w-4' aria-hidden='true' />
    </button>
  );
};

export default DeviceStatus;
