import { FormikErrors } from 'formik';
import isEmpty from 'validator/lib/isEmpty';
import { IModule } from '../module';
import { ITraining } from '../training';

export interface ITrainingFormModel {
  _id?: string;
  name: string;
  modules: IModule[];
}

export function createTrainingForm(
  training?: ITraining | null
): ITrainingFormModel {
  return {
    name: training ? training.name : '',
    modules: training ? [...training.modules] : [],
  };
}

export function validateTraining(
  values: ITrainingFormModel
): FormikErrors<ITrainingFormModel> {
  const errors: FormikErrors<ITrainingFormModel> = {};

  if (isEmpty(values.name)) errors.name = 'required';

  return errors;
}
