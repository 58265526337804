import React from 'react';

interface Props {
  title: string;
  rightButton?: React.ReactNode;
}

const FormTitle: React.FC<Props> = ({ title, rightButton }: Props) => {
  return (
    <>
      <div className='flex justify-between items-center'>
        <div>
          <h3 className='font-semibold text-xl uppercase'>{title}</h3>
        </div>
        <div>{rightButton}</div>
      </div>
      <hr className='border-y-[1px] mb-6 border-[#C9002B] my-2' />
    </>
  );
};

export default FormTitle;
