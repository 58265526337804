import React from 'react';

import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';

interface Props {
  children: React.ReactNode;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}

const DndContext: React.FC<Props> = ({ children, onDragEnd }: Props) => {
  return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
};

export default DndContext;
