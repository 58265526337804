import React from 'react';

import { useParams } from 'react-router-dom';
import isMongoId from 'validator/lib/isMongoId';
import FormContainer from '../../app/components/common/FormContainer';

import useTrainings from '../../app/hooks/useTrainings';
import { ITraining } from '../../app/models';
import TrainingForm from '../../app/components/trainings/TrainingForm';

const TrainingPage: React.FC = () => {
  const { training, isLoading } = useTraining();

  return (
    <>
      <FormContainer>
        {!isLoading && <TrainingForm training={training} />}
      </FormContainer>
    </>
  );
};

const useTraining = (): {
  training: ITraining | undefined;
  isLoading: boolean;
} => {
  const { trainings, isLoading } = useTrainings();
  const { id } = useParams();

  if (isMongoId(id ? id : '') && !isLoading) {
    return {
      training: trainings ? trainings.find((e) => e._id === id) : undefined,
      isLoading,
    };
  } else {
    return { training: undefined, isLoading };
  }
};

export default TrainingPage;
