import React from 'react';
import { Route, Routes } from 'react-router-dom';

import TrainingsPage from './TrainingsPage';
import TrainingPage from './TrainingPage';

const TrainingRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<TrainingsPage />} />
      <Route path='/:id' element={<TrainingPage />} />
    </Routes>
  );
};

export default TrainingRoutes;
