import React from 'react';
import { useParams } from 'react-router-dom';
import isMongoId from 'validator/lib/isMongoId';
import FormContainer from '../../app/components/common/FormContainer';
import LoadingSpinner from '../../app/components/layout/LoadingSpinner';
import UserForm from '../../app/components/users/UserForm';
import useUsers from '../../app/hooks/useUsers';
import { IUser } from '../../app/models';

const UserPage = () => {
  const { isLoading } = useUsers();
  const user = useUser();

  return (
    <>
      <FormContainer>
        {isLoading && <LoadingSpinner />}
        {!isLoading && <UserForm user={user} />}
      </FormContainer>
    </>
  );
};

const useUser = (): IUser | undefined => {
  const { users, isLoading, isError } = useUsers();
  const { id } = useParams();

  if (isError) {
    return undefined;
  }

  if (isMongoId(id ? id : '') && !isLoading) {
    return users ? users.find((e) => e._id === id) : undefined;
  } else {
    return undefined;
  }
};

export default UserPage;
