import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

interface Props {
  backUrl?: string;
  onBackClick?: () => void;
}

const CloseButton: React.FC<Props> = ({ backUrl, onBackClick }) => {
  const navigate = useNavigate();

  const onBack = (): void => {
    if (backUrl) {
      navigate(backUrl);
    } else {
      navigate(-1);
    }
    onBackClick && onBackClick();
  };

  return (
    <button
      type='button'
      onClick={onBack}
      className='p-3 border border-transparent rounded-full shadow-sm text-white primary-background hover:bg-[#C9002B] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#C9002B]'
    >
      <XIcon className='h-5 w-5' aria-hidden='true' />
    </button>
  );
};

export default CloseButton;
