import React from 'react';

import { Menu } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import usePropterUtils from '../../hooks/usePropterUtils';

interface Props {
  id: string;
  onClickExclude: (id: string) => void;
}

const ModuleTileMenu: React.FC<Props> = ({ id, onClickExclude }) => {
  const { classNames } = usePropterUtils();

  return (
    <Menu.Item>
      {({ active }) => (
        <div className='flex flex-col items-center justify-center content-center'>
          <button
            onClick={() => onClickExclude(id)}
            className={classNames(
              active ? 'bg-yellow-200' : '',
              'flex w-full px-4 py-2 text-sm text-gray-700 font-bold'
            )}
          >
            <XIcon className='w-5 h-5 mr-5 text-[#C9002B]' />
            Exclude
          </button>
        </div>
      )}
    </Menu.Item>
  );
};

export default ModuleTileMenu;
