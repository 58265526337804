import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { IUser } from '../../models';
import TablePageHeader from '../layout/TablePageHeader';
import TableContainer from '../layout/TableContainer';
import TableHead from '../layout/TableHead';
import DeleteModal from '../layout/DeleteModal';
import useUsers from '../../hooks/useUsers';

interface Props {
  users: IUser[];
}

const UsersTable: React.FC<Props> = ({ users }) => {
  const navigate = useNavigate();
  const { deleteMutation } = useUsers();
  const [openModal, setOpenModal] = useState(false);
  const [deletedId, setDeleteId] = useState('');

  return (
    <div className='px-4 sm:px-6 lg:px-8 mt-6'>
      <DeleteModal
        isOpenModal={openModal}
        onClose={() => {
          setDeleteId('');
          setOpenModal(false);
        }}
        onContinue={() => {
          setOpenModal(false);
          deleteMutation.mutateAsync(deletedId);
          setDeleteId('');
        }}
      />
      <TablePageHeader
        title='Users'
        subtitle='A list of users'
        addButtonText='Add user'
        onClickAddButton={() => navigate('./new')}
      />
      <TableContainer>
        <TableHead headTitles={['User name', 'Role', 'Actions']} />
        <tbody className='divide-y divide-gray-200 bg-white'>
          {users.map((user, userIdx) => (
            <tr key={user._id}>
              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                {user.name}
              </td>
              <td className='px-3 py-4 text-sm text-gray-500'>
                {user ? user.role?.text : ''}
              </td>

              <td className='whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'>
                <button
                  onClick={() => navigate(`./${user._id}`)}
                  type='button'
                  className='inline-flex m-1 items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                >
                  Edit
                  <span className='sr-only'>{user.name}</span>
                </button>
                <button
                  onClick={() => {
                    setDeleteId(user._id!);
                    setOpenModal(true);
                  }}
                  type='button'
                  className='inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
                >
                  Delete
                  <span className='sr-only'>{user.name}</span>
                </button>
                {userIdx !== 0 ? (
                  <div className='absolute right-6 left-0 -top-px h-px bg-gray-200' />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </TableContainer>
    </div>
  );
};

export default UsersTable;
