import React from 'react';

import ReportsTable from '../../app/components/reports/ReportsTable';

const ReportsPage: React.FC = () => {
  return (
    <>
      <ReportsTable />
    </>
  );
};

export default ReportsPage;
