import React from 'react';

interface Props {
  touched: boolean;
  error: string | undefined;
}

const ValidationError: React.FC<Props> = ({ touched, error }: Props) => {
  if (!touched || !error) return null;
  let message = '';

  switch (error) {
    case 'required':
      message = 'Field is requird';
      break;

    case 'email':
      message = 'Please enter a valid email ';
      break;

    case 'complexPassword':
      message = 'Password must be complex';
      break;

    case 'confirmPassword':
      message = 'Confirm password is incorrect';
      break;

    default:
      break;
  }

  return (
    <div className='text-xs font-semibold text-red-500 pl-1'>{message}</div>
  );
};

export default ValidationError;
