import React from 'react';

import { useField } from 'formik';
import Select from 'react-select';
import { getSelectedStyles, selectTheme } from '../../../styles/styles';

import useTraining from '../../hooks/useTrainings';
import ValidationError from './ValidationError';

interface Props {
  label: string;
  name: string;
  className?: string;
}

const TrainingSelect: React.FC<Props> = ({ name, label, className }: Props) => {
  const [field, meta, helpers] = useField(name);

  const { trainings } = useTraining();
  const styles = getSelectedStyles(meta);

  return (
    <div className={className + ' sm:text-sm mb-2'}>
      <label
        htmlFor='translates'
        className='block pl-1 sm:text-sm font-medium text-gray-700'
      >
        {label}
      </label>
      <Select
        id='training-select'
        onBlur={() => helpers.setTouched(true)}
        theme={selectTheme}
        styles={styles}
        getOptionValue={(value) => value}
        getOptionLabel={(value) => value.name}
        menuPlacement='auto'
        menuPosition='fixed'
        options={trainings}
        placeholder={label}
        instanceId={`${name}-select`}
        className='border text-gray-400 mt-1'
        onChange={(values) => helpers.setValue(values)}
        value={field.value}
      />
      <ValidationError touched={meta.touched} error={meta.error} />
    </div>
  );
};

export default TrainingSelect;
