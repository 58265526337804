import React from 'react';

import TrainingsTable from '../../app/components/trainings/TrainingsTable';
import useTrainings from '../../app/hooks/useTrainings';

const TrainingsPage: React.FC = () => {
  const { trainings } = useTrainings();

  return <>{<TrainingsTable trainings={trainings} />}</>;
};

export default TrainingsPage;
