import React, { useEffect, useState } from 'react';

import { ClockIcon } from '@heroicons/react/outline';

interface Props {
  text: string;
  isLoading: boolean;
  disabled?: boolean;
}

const SmallSubmitButton: React.FC<Props> = ({
  text,
  disabled,
  isLoading = false,
}: Props) => {
  const [loading, setLoading] = useState(isLoading);

  useEffect(() => {
    isLoading && setLoading(true);
    const timeout = setTimeout(() => {
      !isLoading && setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <button
      type='submit'
      disabled={disabled}
      className='inline-flex bg-[#005CB4] disabled:bg-blue-200 disabled:text-white 
      items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md 
      text-white hover:bg-[#005CB4] focus:outline-none focus:ring-2 focus:ring-offset-2 
      focus:bg-red-700 sm:text-sm'
    >
      {loading && (
        <>
          {text}
          <ClockIcon className='animate-spin h-4 w-4 ml-2 text-white' />
        </>
      )}
      {!loading && <>{text}</>}
    </button>
  );
};

export default SmallSubmitButton;
