import React from 'react';

import { Draggable, Droppable } from 'react-beautiful-dnd';

import ModuleTile from './ModuleTile';
import { IModule } from '../../models/module';

interface Props {
  modules: IModule[];
  onClickExclude: (id: string) => void;
}

const DroppableModules: React.FC<Props> = ({
  modules,
  onClickExclude,
}: Props) => {
  return (
    <Droppable droppableId='dropable-training'>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {modules
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((module, index) => (
              <Draggable
                key={module._id}
                draggableId={module._id!}
                index={index}
              >
                {(provided) => (
                  <ModuleTile
                    provided={provided}
                    name={module.name}
                    id={module._id!}
                    onClickExclude={onClickExclude}
                  />
                )}
              </Draggable>
            ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableModules;
